import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Header = () => {
  return (
    <nav className='navbar navbar-expand-md navbar-light bg-light shadow'>
      <div className='container' id='navContainer'>
        <Link to={'/'}>
          <StaticImage
            src='../images/legacy-home-protect-logo.png'
            alt='Legacy Home Protect'
            placeholder='blurred'
            height={100}
            formats={['auto', 'webp', 'avif']}
            quality={100}
          ></StaticImage>
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarCollapse'
          aria-controls='navbarCollapse'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarCollapse'>
          <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
            <li className='nav-item'>
              <Link
                className='nav-link text-primary'
                to={'/'}
                activeClassName='active-link active'
              >
                Home
              </Link>
            </li>
            <li className='nav-item dropdown'>
              <Link
                className='nav-link dropdown-toggle text-primary'
                to={''}
                data-bs-toggle='dropdown'
                role='button'
                aria-expanded='false'
              >
                Products
              </Link>
              <ul className='dropdown-menu shadow'>
                <li>
                  <Link
                    className='dropdown-item'
                    to={'/combined-home-insurance'}
                    activeClassName='active'
                  >
                    Combined Home Insurance
                  </Link>
                </li>
                <li>
                  <Link
                    className='dropdown-item'
                    to={'/landlords-insurance'}
                    activeClassName='active'
                  >
                    Landlords Insurance
                  </Link>
                </li>
                <li>
                  <Link
                    className='dropdown-item'
                    to={'/home-emergency-cover'}
                    activeClassName='active'
                  >
                    Home Emergency Cover
                  </Link>
                </li>
              </ul>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link text-primary'
                to={'/faq'}
                activeClassName='active-link active'
              >
                FAQ
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link text-primary'
                to={'/claim'}
                activeClassName='active-link active'
              >
                Claim
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link text-primary'
                to={'/contact-us'}
                activeClassName='active-link active'
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header
