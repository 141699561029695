import { Link } from 'gatsby'
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faLocationArrow,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
  return (
    <footer className='bg-light py-5 container-fluid'>
      <div className='row justify-content-center'>
        <h3 className='text-center'>Contact Us</h3>

        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-auto text-end text-primary'>
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className='col-auto'>
              <a href='tel:03454503359'>0345 450 3359</a>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-auto text-end text-primary'>
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className='col-auto'>
              <a href='mailto:info@legacyhp.co.uk'>info@legacyhp.co.uk</a>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-auto text-end text-primary'>
              <FontAwesomeIcon icon={faLocationArrow} />
            </div>
            <div className='col-auto'>
              Provincial House, 37 New Walk, Leicester, LE1 6TE
            </div>
          </div>
        </div>

        <Link className='text-center my-3' to={'/privacy'}>
          Privacy Policy
        </Link>

        <p className='text-center'>
          &copy; Legacy Home Protect {new Date().getFullYear()}
        </p>

        <div className='row justify-content-center'>
          <div className='col-lg-6 text-center'>
            <p className='text-muted'>
              Legacy Home Protect Limited is an appointed representative of
              Cavere Limited, Unit 2, Horizon Court, Audax Close, Clifton Moor,
              York, YO30 4RB which is authorised and regulated by the Financial
              Conduct Authority. The FCA Register Number is 517325. You can
              check this on the FCA's Register by visiting the FCA's
              Websiteregister or by contacting the FCA on 0845 606 1234. Legacy
              Home Protect Limited is registered in England and Wales, number
              8722216, registered office; Provincial House, 37 New Walk, Leicester, LE1 6TE
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
